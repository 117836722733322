import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/blog.module.scss";
import Imgs from "../components/Imgs";
import Masonry from "react-masonry-css";
import "../assets/css/masonry.css"; /* este css lo creé yo, nose si va acá */
import { Link } from "gatsby-plugin-intl";

const BlogPage = () => (
	<Layout>
		<Seo title="Blog" />

		<section className={styles.blog}>
			<article className={styles.sliderBlog}>
				<div className={styles.slider}>
					<div className={styles.item}>
						<img src="/blog/bg/img-01.jpg" alt="" />
					</div>
				</div>
				<div className={styles.blogWrapper}>
					<Link to="/" className="lineLink">
						<u>Volver a la home</u>
					</Link>
					<div className={styles.sliderBullets}>
						<ul>
							<li className={styles.contentHolder}>
								<span
									className={styles.content}
									style={{ width: "75%" }}
								></span>
							</li>
							<li className={styles.contentHolder}>
								<span
									className={styles.content}
									style={{ width: "0%" }}
								></span>
							</li>
							<li className={styles.contentHolder}>
								<span
									className={styles.content}
									style={{ width: "0%" }}
								></span>
							</li>
							<li className={styles.contentHolder}>
								<span
									className={styles.content}
									style={{ width: "0%" }}
								></span>
							</li>
						</ul>
						<div className={styles.sliderTitle}>
							<h3>
								La playa puede esperar, en Semana Santa nos vamos a la
								nieve
							</h3>
							<Link to="/blog">Leer artículo</Link>
						</div>
					</div>
				</div>
			</article>

			<article className={styles.contentBlog}>
				<div className={styles.text}>
					<h3>El blog más fresh</h3>
				</div>
				<div className={styles.images}>
					<Masonry
						breakpointCols={3}
						className="my-masonry-grid"
						columnClassName="my-masonry-grid_column"
					>
						<div style={{ height: "500px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "250px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "600px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "475px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "475px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "500px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "500px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "500px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div style={{ height: "475px" }}>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
						<div>
							<Link to="/blog">
								<Imgs
									title="Title"
									subtitle="Subtitle"
									linkText="Link"
									linkTo="/"
								/>
							</Link>
						</div>
					</Masonry>
				</div>
			</article>
		</section>
	</Layout>
);

export default BlogPage;
