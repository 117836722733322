import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";
import { Link } from "gatsby-plugin-intl";

const Imgs = ({ title, subtitle, imagen, linkText, linkTo }) => {
	return (
		<Link to={linkTo} className={styles.item}>
			{imagen && <img src={imagen.url} alt={title} />}
			<div className={styles.text}>
				{title && <h3>{title}</h3>}
				{subtitle && <p>{subtitle}</p>}
				{linkText && linkTo && <span>{linkText}</span>}
			</div>
		</Link>
	);
};

Imgs.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	imagen: PropTypes.shape({
		url: PropTypes.string,
		dataUrl: PropTypes.string,
		width: PropTypes.number,
		height: PropTypes.number,
	}),
	linkText: PropTypes.string,
	linkTo: PropTypes.string,
};

Imgs.defaultProps = {
	title: null,
	subtitle: null,
	imagen: null,
	linkText: null,
	linkTo: null,
};

export default Imgs;
